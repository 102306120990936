import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'
import Section from '../../../components/dai/Section'
import IconCard from '../../../components/dai/IconCard'
import CalloutCard from '../../../components/dai/CalloutCard'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import PageNavigation from '../../../components/dai/PageNavigation'
import { BoldText } from '../../../components/dai/BoldText'
import FAQ from '../../../components/dai/FAQ'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'
import TextLink from '../../../components/common/TextLink'

import PhotoVideo from '../../../images/dai/icons/assets/photo-video.svg'
import BrokenComputer from '../../../images/dai/icons/assets/broken-computer.svg'
import TestFailed from '../../../images/dai/icons/assets/test-failed.svg'

const MaxImage = styled.div`
  max-width: 200px;
`

const DesktopOnly = styled.div`
  @media (max-width: 1199px) {
    display: none;
  }
`

const MobileOnly = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }
`

export default function ImageAccessibilityTips() {
  return (
    <LayoutDAI showKeyInsights pagePath={{ parent: 'key-insights', child: 'image-accessibility-tips' }}>
      <SEO
        title="Image Accessibility Tips | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/key-insights/image-accessibility-tips/"
        desc="After scanning more than 32 million images, we found that 93% of domains tested had at least one page with an inaccessible image."
        bannerUrl="https://images.prismic.io/audioeye-web/89e5d504-e5dc-4898-9c61-66bbbbda9354_form-accessibility-images.png?auto=compress,format"
        imageAlt="Stylized illustration showing both accessible and inaccessible images."
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/key-insights/image-accessibility-tips/`}
      />
      <Section paddingTop="md" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          Image Accessibility Tips
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Image Accessibility Tips">
          Bold visuals can grab people’s attention, but image accessibility lags behind.
        </Heading1>
      </Section>
      <Section paddingTop="md" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text>You know the old adage “A picture is worth a thousand words”?</Text>
        <Space height={32} />
        <Text>
          That might be an understatement today. In fact, research has shown that{' '}
          <BoldText>91% of consumers prefer visual content over text-based content</BoldText> (
          <TextLink
            to="https://www.forbes.com/sites/forbestechcouncil/2018/04/02/visual-content-the-future-of-storytelling/?sh=59aba83a4652"
            target="_blank"
            rel="noreferrer"
            text="Forbes"
            anchorClassName="unstyled-link dai-link"
          />
          ).
        </Text>
        <Space height={32} />
        <Text>
          The world’s leading brands have taken note. But as the web becomes increasingly visual, it raises an important
          question for accessibility: What are organizations doing to include people who cannot perceive images
          visually?
        </Text>
        <Space height={32} />
        <Text>Unfortunately, the answer is often “not enough.”</Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <CalloutCard>
          <Heading2 heading2 className="asH3" color="purple800">
            4 out of 7 images are not accessible to people with visual impairments.
          </Heading2>
          <Space height={32} />
          <Text bodyMedium color="purple800">
            4 out of 7 images (or 56% of all images scanned) had{' '}
            <BoldText>faulty or missing image alternative text</BoldText> — which can make it difficult for people with
            visual and cognitive impairments to understand what an image is supposed to represent.
          </Text>
          <Space height={32} />
          <DesktopOnly>
            <StaticImage
              src="../../../images/dai/callout-card/4-7-images-desktop.png"
              alt="4 broken images and 3 normal images representing 4 out of 7 images that are inaccessible."
            />
          </DesktopOnly>
          <MobileOnly>
            <StaticImage
              src="../../../images/dai/callout-card/4-7-images-mobile.png"
              alt="4 broken images and 3 normal images representing 4 out of 7 images that are inaccessible."
            />
          </MobileOnly>
        </CalloutCard>
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text>
          After scanning more than 32 million images, we found that 93% of domains tested had at least one page with an
          inaccessible image. And of those domains, 69% of pages had at least one image that failed one of our tests.
        </Text>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Columns unorderedList>
          <Column size={8} listItem>
            <IconCard noCard>
              <PhotoVideo />
              <Space height={16} />
              <Text xLargeBodyBold>32M</Text>
              <Space height={16} />
              <Text smallBodyMedium>number of images tested across all domains</Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <BrokenComputer />
              <Space height={16} />
              <Text xLargeBodyBold>93%</Text>
              <Space height={16} />
              <Text smallBodyMedium>of domains had at least one page with faulty image alt text</Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <TestFailed />
              <Space height={16} />
              <Text xLargeBodyBold>69%</Text>
              <Space height={16} />
              <Text smallBodyMedium>of pages had at least one image that failed one of our tests</Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Heading2 heading2 className="asH3">
          When it comes to accessibility, the main culprit is image alternative text.
        </Heading2>
        <Space height={32} />
        <Text>
          Image alternative text, or <BoldText>alt text</BoldText>, is a written description of an image that screen
          readers can read out loud — or convert to Braille — for people with visual impairments, sensory processing
          disorders, or learning disorders.
        </Text>
        <Space height={32} />
        <Text>
          Done right, alt text can paint a complete picture of a website for people who cannot perceive images visually.
          However, many websites forget to provide alt text for each image. Or they write something that’s so vague it
          doesn’t provide any value to the user.
        </Text>
        <Space height={32} />
        <Text>
          Imagine driving down a road and seeing a blank speed limit sign. Or one that simply said “Don’t Speed.”
          Neither sign would be all that helpful, without knowing the actual speed limit.
        </Text>
        <Space height={32} />
        <Text>
          Unfortunately, that’s the experience people with visual impairments have when they encounter an image with
          vague or missing alt text.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns unorderedList>
          <Column size={8} listItem>
            <IconCard noCard>
              <MaxImage>
                <StaticImage
                  src="../../../images/dai/columns/speed-limit-blank.png"
                  alt="A blank traffic sign to create an analogy for no alt text."
                />
              </MaxImage>
              <Space height={32} />
              <Text xLargeBodyBold>No Alt Text</Text>
              <Space height={16} />
              <Text smallBodyMedium>{`alt: <blank>`}</Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <MaxImage>
                <StaticImage
                  src="../../../images/dai/columns/speed-limit-dont-speed.png"
                  alt="A traffic sign that reads “Don’t Speed” to create an analogy for unhelpful alt text."
                />
              </MaxImage>
              <Space height={32} />
              <Text xLargeBodyBold>Bad Alt Text</Text>
              <Space height={16} />
              <Text smallBodyMedium>{`alt: <don’t speed>`}</Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <MaxImage>
                <StaticImage
                  src="../../../images/dai/columns/speed-limit-25.png"
                  alt="A traffic sign that reads “Speed Limit 25” to create an analogy for helpful alt text."
                />
              </MaxImage>
              <Space height={32} />
              <Text xLargeBodyBold>Good Alt Text</Text>
              <Space height={16} />
              <Text smallBodyMedium>{`alt: <speed limit sign indicating 25 miles per hour>`}</Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text>
          Our scan revealed that the average page had 8.6 images. And of those images,{' '}
          <BoldText>56% had an accessibility issue</BoldText>.
        </Text>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Columns unorderedList>
          <Column size={8} listItem>
            <IconCard noCard>
              <MaxImage>
                <StaticImage
                  src="../../../images/dai/columns/stop-sign-blank.png"
                  alt="A blank stop sign to create an analogy for no alt text."
                />
              </MaxImage>
              <Space height={32} />
              <Text xLargeBodyBold>60%</Text>
              <Space height={16} />
              <Text smallBodyMedium>had no alt text</Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <MaxImage>
                <StaticImage
                  src="../../../images/dai/columns/stop-sign-hand.png"
                  alt="A stop sign with an icon of a hand to create an analogy for images not marked as decorative."
                />
              </MaxImage>
              <Space height={32} />
              <Text xLargeBodyBold>30%</Text>
              <Space height={16} />
              <Text smallBodyMedium>were not marked as decorative</Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <MaxImage>
                <StaticImage
                  src="../../../images/dai/columns/stop-sign-stop-halt-wait.png"
                  alt="A stop sign with the words “Stop Halt Wait” to create an analogy for images with redundant text."
                />
              </MaxImage>
              <Space height={32} />
              <Text xLargeBodyBold>10%</Text>
              <Space height={16} />
              <Text smallBodyMedium>had redundant text</Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" paddingBottom="md" constraint="lg" backgroundColor="orange200">
        <FAQ
          questions={[
            {
              title: 'Lead with the most important info',
              text: 'Try to put the most important information first, so people aren’t left wondering why you’re describing every minute detail of an image.',
            },
            {
              title: 'Be descriptive',
              text: 'People using screen readers can ignore what you write, but they can’t ignore what isn’t there. Include key details and defining traits.',
            },
            {
              title: 'Don’t start with “Image of” or “Picture of”',
              text: 'Screen readers will know from the preceding HTML tag to announce the alt text as an image — so including these phrases in the alt text will only disrupt the user experience.',
            },
            {
              title: 'Include readable text',
              text: 'If your images have text (for example: labels that explain product features or benefits), make sure they are either listed in the alt text or described nearby on the page.',
            },
          ]}
        >
          <Text largeCapsInter color="orange900">
            BEST PRACTICES
          </Text>
          <Space height={24} />
          <Text heading2 className="asH3">
            Image accessibility tips
          </Text>
          <Space height={24} />
        </FAQ>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/key-insights/"
        previousPageText="Key Insights"
        nextPageLink="/digital-accessibility-index/key-insights/link-accessibility-tips/"
        nextPageText="Link Accessibility Tips"
      />
    </LayoutDAI>
  )
}
